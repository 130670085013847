import { Input } from 'antd'
import FormatNumberSmall from 'components/FormatNumberSmall'
import { DEFAULT_DECIMAL } from 'config/constants'
import { TimeDataType } from 'hooks/opvStaking/useGetListPools'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { toLocaleString } from 'utils'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'
import { Skeleton } from 'widgets'

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1.3fr;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: 1fr 1.5fr;
  }
`

const BoxWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #ffffff66;
  background: radial-gradient(
    340.54% 169.54% at 119.18% 126.53%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(6, 6, 6, 0.6) 48.58%,
    rgba(27, 27, 27, 0.6) 100%
  );

  ${({ theme }) => theme.mediaQueries.lg} {
    backdrop-filter: blur(7.5px);
  }

  &.calculate-box {
    ${({ theme }) => theme.mediaQueries.lg} {
      backdrop-filter: blur(2px);
    }
  }
`

const Tabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 100px;
  border: 1px solid #2f2f2f66;
  background: #000;
  padding: 4px;

  .period-item {
    padding: 10px;
    border-radius: 100px;
    cursor: pointer;

    &.active {
      background: #03f1e3;

      p {
        color: #000;
      }
    }
  }
`

const RoiBox = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #03f1e366;
  background: #000;
`

type RoiCalculatorPropType = {
  stakingList: any[]
  timeList: TimeDataType[]
  opxPrice: number
}

const RoiCalculator = ({ stakingList, timeList, opxPrice }: RoiCalculatorPropType) => {
  const [amountStake, setAmountStake] = useState('')
  const [currentDuration, setCurrentDuration] = useState<TimeDataType | undefined>(undefined)

  const handleChangeInput = (value: string) => {
    if (Number.isNaN(+value)) return
    setAmountStake(value)
  }

  const totalStaked = useMemo(() => {
    return stakingList?.reduce((total, cur) => total + +cur?.totalLpSupply / +DEFAULT_DECIMAL, 0)
  }, [stakingList])

  const rewardPerDayPercent = useMemo(() => {
    const foundData = stakingList?.find((item) => item?.poolId === currentDuration?.plan)

    if (foundData) {
      const dailyRewards = (foundData?.cakePerBlock / +DEFAULT_DECIMAL) * 86400
      const totalAmountOfPool = foundData?.totalLpSupply / +DEFAULT_DECIMAL
      const percent = dailyRewards / (totalAmountOfPool > 0 ? totalAmountOfPool : 1)
      return totalAmountOfPool <= 0 ? 0 : percent
    }

    return 0
  }, [currentDuration?.plan, stakingList])

  useEffect(() => {
    if (timeList) {
      setCurrentDuration(timeList[0])
    }
  }, [timeList])

  return (
    <Wrapper>
      <BoxWrapper className="flex flex-col md:p-8 py-6 px-4 md:gap-6 gap-4">
        <div className="flex items-center justify-between gap-3">
          <p className="text-[20px] leading-[1.2]">Total</p>
          <p className="text-[20px] leading-[1.2] text-[#A2FDFF]">{stakingList?.length || 0} Pool</p>
        </div>

        <img
          src="/images/staking/calculator-bg.png"
          alt="logo"
          className="w-full sm:max-w-[206px] max-w-[140px] aspect-square mx-auto"
        />

        <div className="flex flex-col items-center gap-3">
          <p className="text-[#A9A9A9] text-[18px] leading-[1.1] text-center">Total Staked</p>
          <p className="md:text-[36px] text-[28px] text-center">
            {totalStaked ? formatAmountOrigin(totalStaked) : '0'} OPX
          </p>
          <p className="text-[#C1C1C1] text-[18px] leading-[1.1] text-center">
            ~{' '}
            {totalStaked ? (
              +totalStaked * opxPrice < 0.01 ? (
                <FormatNumberSmall number={(+totalStaked * opxPrice).toFixed(12)} />
              ) : (
                formatAmountOrigin(+totalStaked * opxPrice)
              )
            ) : (
              '--'
            )}
            $
          </p>
        </div>
      </BoxWrapper>

      <BoxWrapper className="calculate-box md:px-6 md:py-8 py-6 px-4 flex flex-col gap-3">
        <p className="text-[20px] leading-[1.2]">ROI Calculator</p>
        <p className="text-sm leading-1 uppercase">OPX STAKED</p>

        <Input
          onChange={(e) => handleChangeInput(e.target.value)}
          value={amountStake}
          className="text-right px-6 py-4 bg-black rounded-[12px] lg:text-[32px] text-[20px]"
          placeholder="0.0"
        />

        <p className="text-sm leading-1 uppercase">STAKED FOR</p>
        <Tabs>
          {timeList ? (
            timeList?.map((time) => (
              <div
                key={time?.plan}
                aria-hidden="true"
                className={currentDuration?.plan === time?.plan ? 'period-item active' : 'period-item'}
                onClick={() => setCurrentDuration(time)}
              >
                <p className="text-center text-[#A1A1AA] md:text-base text-sm">{time?.time} days</p>
              </div>
            ))
          ) : (
            <>
              <Skeleton width="100%" height={44} />
              <Skeleton width="100%" height={44} />
              <Skeleton width="100%" height={44} />
            </>
          )}
        </Tabs>

        {!!stakingList?.find((item) => item?.poolId === currentDuration?.plan) && (
          <>
            <div className="flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
                <path
                  d="M13 2C13 1.17157 12.3284 0.5 11.5 0.5C10.6716 0.5 10 1.17157 10 2H13ZM10.4393 19.0607C11.0251 19.6464 11.9749 19.6464 12.5607 19.0607L22.1066 9.51472C22.6924 8.92893 22.6924 7.97919 22.1066 7.3934C21.5208 6.80761 20.5711 6.80761 19.9853 7.3934L11.5 15.8787L3.01472 7.3934C2.42893 6.80761 1.47919 6.80761 0.893398 7.3934C0.307611 7.97919 0.307611 8.92893 0.893398 9.51472L10.4393 19.0607ZM10 2V18H13V2H10Z"
                  fill="#03F1E3"
                />
              </svg>
            </div>

            <p className="text-sm leading-1 uppercase">ROI AT CURRENT RATE</p>

            <RoiBox className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <p className="text-[20px]">
                  {formatAmountOrigin(+amountStake * +rewardPerDayPercent * +currentDuration?.time)}
                </p>
                <div className="flex items-center gap-2">
                  <img src="/images/logo.png" alt="logo" className="w-6 h-6" />
                  <p className="text-[20px]">OPX</p>
                </div>
              </div>

              <p className="text-[20px]">
                ~{' '}
                {+amountStake * +rewardPerDayPercent * +currentDuration?.time * opxPrice < 0.01 ? (
                  <FormatNumberSmall
                    number={(+amountStake * +rewardPerDayPercent * +currentDuration?.time * opxPrice).toFixed(12)}
                  />
                ) : (
                  formatAmountOrigin(+amountStake * +rewardPerDayPercent * +currentDuration?.time * opxPrice)
                )}
                $
              </p>
            </RoiBox>
          </>
        )}
      </BoxWrapper>
    </Wrapper>
  )
}

export default RoiCalculator
