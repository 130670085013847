/* eslint-disable no-await-in-loop */
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_DECIMAL } from 'config/constants'
import { useOpxContract } from 'hooks/useContract'
import moment from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'

export type TimeDataType = { time: number; plan: number }

const getPools = async (contractMethod, numberOfPools: number, account?: string) => {
  const poolsPromises = []

  for (let i = numberOfPools - 1; i >= 0; i--) {
    let pendingCakePromise = 0
    let userInfoPromise
    const poolPromise = await contractMethod.poolInfo(i)

    if (account) {
      pendingCakePromise = await contractMethod.pendingCake(i, account)
      userInfoPromise = await contractMethod.userInfo(i, account)
    }

    poolsPromises.push({
      ...poolPromise,
      poolId: i,
      allocPoint: +poolPromise?.allocPoint / +DEFAULT_DECIMAL,
      startBlock: +poolPromise?.startBlock,
      endBlock: +poolPromise?.endBlock,
      minimumLockTime: +poolPromise?.minimumLockTime,
      isLocked: poolPromise?.isLocked,
      earn: account ? pendingCakePromise / +DEFAULT_DECIMAL : 0,
      stake: account ? +userInfoPromise[0] / +DEFAULT_DECIMAL : 0,
    })
  }

  const pools = await Promise.all(poolsPromises)

  return pools
}

const useGetListPools = (): [() => Promise<void>, any, TimeDataType[]] => {
  const { account } = useWeb3React()
  const contractMethod = useOpxContract()

  const [data, setData] = useState<any>([])
  const [timeData, setTimeData] = useState<any>(undefined)

  const getData = useCallback(async () => {
    if (contractMethod) {
      try {
        const poolLength = await contractMethod.poolLength()
        const listPools = await getPools(contractMethod, poolLength, account)

        if (listPools?.length > 0) {
          const timeArr = listPools?.map((item) => ({
            time: moment.duration(moment(item?.endBlock * 1000).diff(moment(item?.startBlock * 1000))).asDays(),
            plan: item?.poolId,
          }))

          setData(listPools)
          setTimeData(timeArr)
        }
      } catch (error) {
        console.error('error', error)
      }
    }
  }, [account, contractMethod])

  useEffect(() => {
    getData()
  }, [getData])

  return [getData, data, timeData]
}

export default useGetListPools
