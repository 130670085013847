/* eslint-disable no-restricted-syntax */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react'
import { formatNumber } from 'utils/formatBalance'

export default function FormatNumberSmall({ number }) {
  const stringValue = number.toString()
  const [firstPart, secondPart] = stringValue?.split('.')
  const [checkCount, setCheckCount] = useState(0)

  useEffect(() => {
    let count = 0

    if (secondPart) {
      for (const char of secondPart) {
        if (+char === 0) {
          count += 1
        } else {
          setCheckCount(count)
          break
        }
      }
    }
  }, [secondPart])

  return (
    <>
      {checkCount > 3 ? (
        <span>
          {firstPart}.0<sub className="text-[12px]">{checkCount}</sub>
          {secondPart.slice(checkCount)}
        </span>
      ) : (
        formatNumber(number)
      )}
    </>
  )
}
