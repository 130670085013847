import { useWeb3React } from '@web3-react/core'
import { GAS_PRICE_DEFAULT } from 'config/constants'
import { BASE_STAKING_TOKEN } from 'config/constants/endpoints'
import useGetListPools from 'hooks/opvStaking/useGetListPools'
import useApproveToken from 'hooks/useApproveToken'
import { useCreateTokenContract, useOpxContract } from 'hooks/useContract'
import useGetOpxPrice from 'hooks/useGetOpxPrice'
import { useGetWalletBalance } from 'hooks/useGetWalletBalance'
import useToast from 'hooks/useToast'
import { useCallback, useState } from 'react'
import { getTransactionReceiptMined } from 'utils'
import Banner from './components/Banner'
import ModalStaking from './components/ModalStaking'
import RoiCalculator from './components/RoiCalculator'
import StakingPlan from './components/Staking/StakingPlan'
import StepToStaking from './components/Staking/StepToStaking'
import WrapperStaking from './components/styled'

const CONFIG_TOKEN_STAKING = {
  9000: {
    symbol: 'USDT',
    name: 'USDT',
  },
  56: {
    symbol: 'OPX',
    name: 'Optimus X',
  },
}

const Staking = () => {
  const { account, chainId } = useWeb3React()
  const { toastSuccess, toastError } = useToast()

  const opxPrice = useGetOpxPrice()

  // State
  const [loading, setLoading] = useState(false)
  const [isMyStaking, setIsMyStaking] = useState(false)

  // Contract
  const contractMethod = useOpxContract()
  const tokenStakingContract = useCreateTokenContract(BASE_STAKING_TOKEN)

  // Data, function by contract
  const [fetchPool, poolList, timeList] = useGetListPools()

  const getWalletBalance = useGetWalletBalance(tokenStakingContract, account, {
    chainId,
    symbol: CONFIG_TOKEN_STAKING[chainId]?.symbol || CONFIG_TOKEN_STAKING[56].symbol,
    name: CONFIG_TOKEN_STAKING[chainId]?.name || CONFIG_TOKEN_STAKING[56].name,
  })
  const [approveToken] = useApproveToken()

  const handleModalStaking = () => {
    setIsMyStaking((prev) => !prev)
  }

  const handleDeposit = useCallback(
    async (params, onSuccess) => {
      if (!contractMethod) {
        return setLoading(false)
      }

      try {
        const res = await contractMethod.deposit(params?.plan, params?.amount, {
          from: account,
          value: '0',
          gasPrice: GAS_PRICE_DEFAULT,
        })

        if (res?.hash) {
          const receipt = await getTransactionReceiptMined(res?.hash, 500)

          if (receipt) {
            getWalletBalance()
            fetchPool()
            toastSuccess('Successfully')
            setLoading(false)
            onSuccess()
          }
        }
      } catch (error: any) {
        toastError(error?.message)
        setLoading(false)
      }

      return null
    },
    [account, contractMethod, fetchPool, getWalletBalance, toastError, toastSuccess],
  )

  const handleApprove = useCallback(
    async (params, onSuccess) => {
      if (!loading) {
        setLoading(true)
        approveToken(
          tokenStakingContract,
          account,
          contractMethod?.address,
          params?.amount,
          params?.amount,
          () => {
            handleDeposit(params, onSuccess)
          },
          (error) => {
            toastError(error)
            setLoading(false)
          },
        )
      }
    },
    [loading, approveToken, tokenStakingContract, account, contractMethod?.address, handleDeposit, toastError],
  )

  return (
    <WrapperStaking>
      <Banner />

      <div className="w-full max-w-[1182px] mx-auto md:mt-[90px] mt-[42px] mb-[42px] px-4 flex flex-col gap-8">
        <RoiCalculator stakingList={poolList} timeList={timeList} opxPrice={opxPrice?.current_price?.usd || 0} />
        <StakingPlan
          onApprove={handleApprove}
          loading={loading}
          stakingList={poolList}
          timeList={timeList}
          onMyStaking={handleModalStaking}
          isMyStaking={isMyStaking}
        />
        <StepToStaking />
      </div>

      <ModalStaking getWalletBalance={getWalletBalance} fetchUserStakingInfo={fetchPool} />
    </WrapperStaking>
  )
}

export default Staking
