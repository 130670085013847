import { useEffect, useState } from 'react'

const useGetOpxPrice = (): any => {
  const [data, setData] = useState()

  useEffect(() => {
    async function getData() {
      try {
        const response: any = await (
          await fetch(
            `https://api.coingecko.com/api/v3/coins/optimus-x?localization=false&tickers=false&community_data=false&sparkline=false`,
            {
              method: 'GET',
            },
          )
        ).json()
        if (response) {
          setData(response.market_data)
        }
      } catch (error) {
        console.error(error)
        setData(data)
      }
    }

    if (!data) {
      getData()
      return null
    }

    const fetchDelay = setTimeout(() => {
      getData()
    }, 10000)
    return () => {
      clearInterval(fetchDelay)
    }
  }, [data])

  return data
}

export default useGetOpxPrice
