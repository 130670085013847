import styled from 'styled-components'
import { NoteIcon } from 'widgets'

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  padding: 24px;
  border-radius: 12px;
  border: 1px solid #ffffff66;
  background: radial-gradient(
    340.54% 169.54% at 119.18% 126.53%,
    rgba(0, 0, 0, 0.44) 0%,
    rgba(6, 6, 6, 0.44) 48.58%,
    rgba(27, 27, 27, 0.44) 100%
  );

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr;
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .number {
      color: #000;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.6px;
      text-transform: capitalize;

      padding: 10px;
      border-radius: 8px;
      background-color: #03f1e3;
      min-width: 44px;
      height: auto;
      aspect-ratio: 1/1;
    }

    span {
      color: #fff;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }
    .body {
      color: #fff;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.32px;
      text-transform: capitalize;
    }
  }
`

const StepToStaking = () => {
  return (
    <Wrapper>
      <div className="flex lg:flex-col lg:items-start items-center h-fit gap-1 header">
        <NoteIcon />
        <p className="sm:text-[24px] text-[20px] leading-[1.6] capitalize">Steps to stake OPX?</p>
      </div>

      <div className="steps">
        <div className="flex lg:flex-col flex-row items-center lg:items-start gap-4">
          <p className="number">1.</p>
          <span>Buy OPX</span>
        </div>

        <div className="body">You have to buy OPX on Pancakeswap, Dexview.</div>
      </div>
      <div className="steps">
        <div className="flex lg:flex-col flex-row items-center lg:items-start gap-4">
          <p className="number">2.</p>
          <span>Connect a wallet where you have OPX</span>
        </div>

        <div className="body">Use Metamask or another wallet connected via WalletConnect</div>
      </div>
      <div className="steps">
        <div className="flex lg:flex-col flex-row items-center lg:items-start gap-4">
          <p className="number">3.</p>
          <span>Start earning up to 12%.</span>
        </div>

        <div className="body"> Earn up to 12% rewards annually from staking OPX</div>
      </div>
    </Wrapper>
  )
}

export default StepToStaking
