import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'widgets'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Banner = () => {
  const router = useRouter()
  const [isLoadedBanner, setIsLoadedBanner] = useState(false)

  return (
    <Wrapper>
      <div className="flex flex-col items-center gap-4 relative z-[2]">
        <div
          className="relative w-full max-w-[1432px] md:aspect-[1432/544] aspect-[1432/650] bg-cover bg-center"
          style={{ backgroundImage: isLoadedBanner ? '' : "url('/images/staking/banner-loading.png')" }}
        >
          <Image
            src="/images/staking/banner.png"
            className={`${isLoadedBanner ? 'block' : 'hidden'} w-full h-full object-cover relative z-[-1]`}
            layout="fill"
            onLoadingComplete={() => {
              setIsLoadedBanner(true)
            }}
            loading="lazy"
          />

          <div className="w-full h-full sm:max-w-[50%] max-w-[60%] flex flex-col justify-center lg:gap-6 sm:gap-4 gap-2 xl:pl-[110px] sm:pl-[42px] pl-2 relative z-[1]">
            <h1 className="text-[#03F1E3] md:text-base text-xs" style={{ fontFamily: 'Sora' }}>
              Stake OPX, Earn Token
            </h1>

            <div className="space-y-2">
              <p
                className="text-white xl:text-[60px] lg:text-[42px] md:text-[32px] sm:text-[24px] text-[18px] font-bold "
                style={{ fontFamily: 'Inter' }}
              >
                Stake. Earn.
                <br />
                Claim Rewards.
              </p>

              <p style={{ fontFamily: 'Sora' }} className="md:text-base text-[10px] leading-[1.2]">
                Stake your OPX token and earn OPX TOKEN. Our intuitive, easy to use interface will help you safely stake
                and start earning today!
              </p>
            </div>

            <Box
              className="text-black sm:px-6 sm:py-3 px-3 py-2 rounded-[100px] bg-[#03F1E3] sm:max-w-[160px] max-w-[100px] text-center sm:text-base text-xs cursor-pointer"
              onClick={() => router.push('/')}
            >
              Stake OPX
            </Box>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Banner
