/* eslint-disable @typescript-eslint/no-extra-semi */
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setWalletBalance } from 'state/wallet/actions'

export async function getBalanceByAccount(contract, account, defaultInfoToken) {
  let tokenDefault = {}

  if (!defaultInfoToken) {
    tokenDefault = {}
  } else {
    tokenDefault = { ...defaultInfoToken }
  }
  return new Promise((resolve, reject) => {
    ;(async () => {
      if (contract) {
        const balance = await contract.balanceOf(account)
        const decimals = await contract.decimals()
        const parseBalance = +balance / 10 ** +decimals
        resolve({ balance: parseBalance, decimals, ...tokenDefault })
      } else {
        reject(new Error('Cannot read contract, please try again later.'))
      }
    })()
  })
}

export const useGetWalletBalance = (tokenContract: any, account: any, tokenInfo?: any) => {
  const dispatch = useDispatch()

  const getWalletBalance = useCallback(async () => {
    if (tokenContract && account) {
      try {
        const token: any = await getBalanceByAccount(tokenContract, account, {
          ...tokenInfo,
          tokenAddress: tokenContract?.address,
        })

        if (token) {
          dispatch(setWalletBalance({ [token?.symbol || token?.tokenAddress]: token }))
        }
      } catch (e) {
        console.error('useGetWalletBalance', e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, tokenContract])

  useEffect(() => {
    getWalletBalance()
  }, [getWalletBalance])

  return getWalletBalance
}
